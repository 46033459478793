import {useDispatch, useSelector} from "react-redux";

const Overlay = () =>{
	const dispatch = useDispatch();
	const modalIsOpenLevel1 = useSelector(state => state.multiModal.modals[0].modalIsOpen);
	const modalIsOpenLevel2 = useSelector(state => state.multiModal.modals[1].modalIsOpen);
	const modalIsOpenLevel3 = useSelector(state => state.multiModal.modals[2].modalIsOpen);
	const changeShowOverlay = (lvl) =>{
		dispatch({type: "CLOSE_MODAL", modalLevel: lvl})

		if(lvl === 1){
			const url = new URL(window.location);
			url.search = ''; // Очистка всех параметров

			window.history.pushState({}, '', url);
		}

	}

	return(
		<>
			{/*<div*/}
			{/*	className={`i_overlay overlay-level-1 ${modalIsOpenLevel1 ? '' : 'hide'}`}*/}
			{/*	onClick={() => changeShowOverlay(1)}></div>*/}
			{/*<div*/}
			{/*	className={`i_overlay overlay-level-2 ${modalIsOpenLevel2 ? '' : 'hide'}`}*/}
			{/*	onClick={() => changeShowOverlay(2)}></div>*/}
			{/*<div*/}
			{/*	className={`i_overlay overlay-level-3 ${modalIsOpenLevel3 ? '' : 'hide'}`}*/}
			{/*	onClick={() => changeShowOverlay(3)}></div>*/}
		</>
	)
}

export default Overlay;