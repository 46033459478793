import useLocalStorage from "../hooks/useLocalStorage";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {API_URL} from "../utils/config";

const ForDistributors = () => {
	let projector = document.querySelector('#projector');
	projector.style.display = 'none';

	const {t} = useTranslation();

	const dispatch = useDispatch();
	const [language, setLanguage] = useLocalStorage('language', 'ru');
	const [data, setData] = useState(null);

	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();

	const getData = () => {
		fetch(`${API_URL}/site/for_distributors/`, {
			method: 'POST',
			body: JSON.stringify({language: language}),
			credentials: 'include'
		}).then(response => response.text()).then(data => {
			setData(data)
		})
	}

	useEffect(getData, [])

	return (
		<div>
			{data && (
				<>
					<div className="i_wrapper" dangerouslySetInnerHTML={{ __html: data }}></div>
					<div className="i_for_distributors_button">
						<div className="i_order_button_bottom j_order_show" onClick={()=>{
							dispatch({type: "OPEN_MODAL", modalLevel : 1, modalType: 'orderWinModal'})
						}}>
							{t('I_TO_APPLY')}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ForDistributors;
