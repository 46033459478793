import {useEffect} from "react";

const useURLParamWatcher = (paramName, callback) => {
	useEffect(() => {
		const handleURLChange = () => {
			const url = new URL(window.location.href);
			const paramValue = url.searchParams.get(paramName);
			callback(paramValue);
		};

		// Отслеживаем изменения URL при монтировании и изменении URL
		handleURLChange();

		// Отслеживаем изменение URL с помощью проксирования методов history API
		const originalPushState = window.history.pushState;
		const originalReplaceState = window.history.replaceState;

		window.history.pushState = function(...args) {
			originalPushState.apply(this, args);
			handleURLChange();
		};

		window.history.replaceState = function(...args) {
			originalReplaceState.apply(this, args);
			handleURLChange();
		};

		// Удаляем обработчики при размонтировании
		return () => {
			window.history.pushState = originalPushState;
			window.history.replaceState = originalReplaceState;
		};
	}, [paramName, callback]);
};

export default useURLParamWatcher;

