import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

const HomePage = () =>{

	const {t} = useTranslation();
	const dispatch = useDispatch();

	const authorized = useSelector(state => state.userData.authorization);

	let projector = document.querySelector('#projector')
	projector.style.display = 'block'

	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();

	const buttonHandler = () => {
		if(!authorized){
			dispatch({type: "OPEN_MODAL", modalLevel : 1,modalType: lastSegment === 'one' ? 'authCountiesModal' : 'authFormModal', isAuth: false})
		}else{
			window.location.href = `${lastSegment === 'd' ? 'http' : 'https'}://cabinet.${currentDomain}`
		}
	}

	return(
		<>
			<div className={'i_middle'}>
				<span className={'i_title'}>
					<span>{t('I_MAIN_SERVICE')}</span>
					<br/>
					<span>{t('I_MAIN_DISTRIBUTION')}</span>
					<br/>
					<span>{t('I_MAIN_DATA')}</span>
				</span>
				<div className="i_order_button_bottom j_order_show i_order_button_first" onClick={buttonHandler}>
					{t('I_START_FREE')}
				</div>
			</div>
			<div className="i_thesis">
				<div className="i_thesis-item">
					<span>{t('I_THESIS_1')}</span>
				</div>
				<div className="i_thesis-item">
					<span>{t('I_THESIS_2')}</span>
				</div>
				<div className="i_thesis-item">
					<span>{t('I_THESIS_3')}</span>
				</div>
				<div className="i_thesis-item">
					<span>{t('I_THESIS_4')}</span>
				</div>
				<div className="i_thesis-item">
					<span>{t('I_THESIS_5')}</span>
				</div>
			</div>
			<div className="i_order_button_bottom j_order_show i_order_button_second" onClick={buttonHandler}>
				{t('I_START_FREE')}
			</div>
		</>
	)
}

export default HomePage