import {useEffect, useState} from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import {useTranslation} from "react-i18next";
import {API_URL} from "../utils/config";

const ContactsModal = () => {
	const [language, setLanguage] = useLocalStorage('language', 'ru');
	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();
	const [data, setData] = useState(null);
	const {t} = useTranslation();

	const getData = () => {
		fetch(`${API_URL}/site/contacts/`, {
			method: 'POST',
			body: JSON.stringify({language: language}),
			credentials: 'include'
		}).then(response => response.text()).then(data => {
			setData(data);
		});
	};

	useEffect(getData, []);

	return(
		<div className="i_contacts">
			<div className="i_contacts-title">
				<span>{t('I_CONTACTS')}</span>
			</div>
			<div className="i_wrapper" dangerouslySetInnerHTML={{__html: data}}></div>
		</div>
	);
}

export default ContactsModal;
