import useLocalStorage from "../hooks/useLocalStorage";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {API_URL} from "../utils/config";

const ForResellers = () => {

	let projector = document.querySelector('#projector')
	projector.style.display = 'none'

	const authorized = useSelector(state => state.userData.authorization);

	const dispatch = useDispatch();

	const [language, setLanguage] = useLocalStorage('language', 'ru');
	const [data, setData] = useState(null);
	const {t} = useTranslation();

	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();

	const buttonHandler = () => {
		if(!authorized){
			dispatch({type: "OPEN_MODAL", modalLevel : 1,modalType: 'authFormModal', isAuth: true})
		}else{
			window.location.href = `${lastSegment === 'd' ? 'http' : 'https'}://cabinet.${currentDomain}`
		}
	}

	const getData = () => {
		fetch(`${API_URL}/site/for_resellers/`, {
			method: 'POST',
			body: JSON.stringify({language: language}),
			credentials: 'include'
		}).then(response => response.text()).then(data => {
			setData(data)
		})
	}

	useEffect(getData, [])

	return (
		<div>
			{data && (
				<>
					<div className="i_wrapper" dangerouslySetInnerHTML={{ __html: data }}></div>
					<div className="i_for_distributors_button">
						<div className="i_order_button_bottom j_order_show" onClick={buttonHandler}>
							{t('I_START_FREE')}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default ForResellers;