import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";

//MUI components
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../styles/customMui/customTabs.scss'

import DetailProps from "./components/dealer/DetailProps";
import DetailParams from "./components/dealer/DetailParams";
import DetailAttribute from "./components/dealer/DetailAttribute";
import ImageSwiper from "./components/dealer/ImageSwiper";
import DetailQuantity from "./components/dealer/DetailQuantity";
import DetailPrice from "./components/dealer/DetailPrice";

import closeBtn from '../img/burger-language-arrow.svg';
import {ReactSVG} from "react-svg";
import infoImg from "../img/info.svg";
import Cookies from "js-cookie";
import starLined from '../img/texpo/star-lined.svg'
import starFilled from '../img/texpo/star-filled.svg'
// import Skeleton from "react-loading-skeleton";


const DealerCatalogProductDetailModal = () => {
	const [productDetail, setProductDetail] = useState(null)
	const productId = useSelector(state => state.multiModal.modals[1].modalData.productId)
	const [itemShowCount, setItemShowCount] = useState(5)
	const [showMore, setShowMore] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const dispatch = useDispatch()

	const cookiesFavoriteProduct = Cookies.get('favoriteProduct')
	const favoriteProductStore = useSelector(state => state.favorite.favoriteList)
	const [favoriteProduct, setFavoriteProduct] = useState(cookiesFavoriteProduct ? JSON.parse(cookiesFavoriteProduct) : [])

	useEffect(() => {
		setFavoriteProduct(favoriteProductStore)
	}, [favoriteProductStore])

	//Табы для props
	const [currentPropsTab, setCurrentPropsTab] = useState('attributes')

	const handleChangePropsTab = (event, newValue) => {
		setCurrentPropsTab(newValue);
	};

	//Табы для props
	const [currentLeftTab, setCurrentLeftTab] = useState('price')

	const handleChangeLeftTab = (event, newValue) => {
		setCurrentLeftTab(newValue);
	};

	//Получаем данные с сервера

	const getData = async () => {
		try {
			const productDetail = await api.texpoApi.getCatalogProductDetail({id: productId});
			setProductDetail(productDetail.product)

			console.log(productDetail)

			// Ставлю искуственную задержку чтобы не лагала модалка при открытии
			setTimeout(() => {
				setIsLoading(false)
			},300)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	//Показываем больше свойств

	const showMoreProps = () => {
		if (itemShowCount === 5) {
			setItemShowCount(100)
			setShowMore(true)
		} else {
			setItemShowCount(5)
			setShowMore(false)
		}
	}

	const addToFavorite = (e, productID) => {
		e.stopPropagation();
		let updatedFavoriteProduct
		// Проверяем, есть ли productID в массиве favoriteProduct
		if (favoriteProduct.includes(productID)) {
			// Если продукт уже есть в массиве, удаляем его
			updatedFavoriteProduct = favoriteProduct.filter(item => item !== productID)
		} else {
			// Если продукта нет, добавляем его
			updatedFavoriteProduct = [...favoriteProduct, productID]
		}

		dispatch({type: "SET_FAVORITE_LIST", favoriteList: updatedFavoriteProduct})
		setFavoriteProduct(updatedFavoriteProduct)

		Cookies.set('favoriteProduct', JSON.stringify(updatedFavoriteProduct), {expires: 365})
	}

	return (
		<>
			{/*Скелетон для прогрузки страницы*/}

			{!isLoading && productDetail && (
				<div className={`i_product-detail`}>
					<div className="i_product-detail-title">
						<span>{productDetail.name}</span>
					</div>
					<div className="i_product-detail-favorite"  onClick={(e)=>{addToFavorite(e, productDetail.id)}}>
						{
							favoriteProduct.includes(productDetail.id) ? <ReactSVG className={'icon-filled'} src={starFilled} /> : <ReactSVG src={starLined} />
						}
					</div>
					<div className="i_product-detail-content">
						<div className="i_product-detail-content-left">
							{/*СВАЙПЕР*/}
							<div className="i_product-detail-images">
								{productDetail.images.length > 0 && (
									<ImageSwiper images={productDetail.images}/>
								)}
								{
									productDetail.images?.noimage && (
										<ReactSVG src={productDetail.images.noimage}
										          className={'i_product-detail-noimage'}/>
									)
								}
							</div>

							<TabContext value={currentLeftTab} className={'i_tabs'}>
								{/*ТАБ ПАНЕЛЬ*/}
								<TabList onChange={handleChangeLeftTab}
								         indicatorColor={'transparent'}
								         className={'i_tabs-header i_tabs-header-left'}>
									<Tab label="Цены" value="price" className={'i_tabs-header-item'}/>
									<Tab label="Остатки" value="quantity" className={'i_tabs-header-item'}/>
								</TabList>

								{/*ЦЕНЫ*/}
								<TabPanel value="price" className={'i_tabs-content-left'}>
									<DetailPrice price={productDetail.prices}/>
								</TabPanel>

								{/*ОСТАТКИ*/}
								<TabPanel value="quantity" className={'i_tabs-content-left'}>
									<DetailQuantity quantity={productDetail.quantity}/>
								</TabPanel>
							</TabContext>

						</div>
						<div className="i_product-detail-content-right">
							<div className="i_product-detail-props">
								<TabContext value={currentPropsTab} className={'i_tabs'}>

									{/*ВВЕРХНЯЯ ТАБ ПАНЕЛЬ*/}
									<TabList onChange={handleChangePropsTab}
									         indicatorColor={'transparent'}
									         className={'i_tabs-header'}
									         variant="scrollable"
									         scrollButtons="auto">
										<Tab label="Атрибуты" value="attributes" className={'i_tabs-header-item'}/>
										<Tab label="Свойства" value="props" className={'i_tabs-header-item'}/>
										<Tab label="Параметры" value="params" className={'i_tabs-header-item'}/>
									</TabList>

									{/*АТРИБУТЫ*/}
									<TabPanel value="attributes">
										<DetailAttribute attribute={productDetail} itemShowCount={itemShowCount}/>
									</TabPanel>

									{/*СВОЙСТВА*/}
									{
										productDetail.props?.props && productDetail.props?.props !== 'null' ? (
												<TabPanel value="props" className={'i_tap-panel'}>
													<DetailProps props={productDetail.props.props}
													             itemShowCount={itemShowCount}/>


													{/*Показываем блок раскрытия тадлицы свойств*/}
													{productDetail.props.props.length > 5 && (
														<div className={`i_show-more ${showMore ? 'show' : ''}`} onClick={showMoreProps}>
															<div className="i_show-more-img">
																<img src={closeBtn} alt=""/>
															</div>
														</div>
													)}

												</TabPanel>
											) :
											<>
												<TabPanel value="props" className={'i_tap-panel'}>
													<div className="i_product-props-empty">
														<div className="i_product-props-empty-title">
															<img src={infoImg} alt=""/>
															<span>Свойства отсутствуют.</span>
														</div>
													</div>
												</TabPanel>
											</>
									}

									{/*ПАРАМЕТРЫ*/}
									{
										productDetail.params && productDetail.params !== 'null' && (
											<TabPanel value="params">
												<DetailParams params={productDetail.params} itemShowCount={itemShowCount}/>
											</TabPanel>
										)
									}
								</TabContext>

							</div>

							{/*ОПИСАНИЕ*/}
							<div className="i_product-detail-description">
								<span className="i_product-detail-content-title">Описание</span>
								{productDetail.description?.description ? (
										<>
										<span>
											{productDetail.description.description}
										</span>
										</>
									) :
									<>
										<div className="i_product-description-empty">
											<div className="i_product-description-empty-title">
												<img src={infoImg} alt=""/>
												<span>Описание отсутствует.</span>
											</div>
										</div>
									</>}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default DealerCatalogProductDetailModal;