import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import global from '../img/global.svg';
import BurgerArrow from '../img/burger-language-arrow.svg';

const LanguageSelector = ({ setCurrentLanguage, language, burger }) => {
	const { t } = useTranslation();
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(language);

	const languageOptions = [
		{ value: 'en', label: 'English'},
		{ value: 'ru', label: 'Русский'},
	];

	const handleToggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const handleSelectLanguage = (value) => {
		setSelectedLanguage(value);
		setCurrentLanguage(value);
		setDropdownOpen(false);
	};

	return (
		<div className="custom-language-selector">
			<div className={`dropdown-arrow ${isDropdownOpen ? 'open' : ''}`}><img src={BurgerArrow} alt="arrow"/></div>
			<div
				className={`selected-language ${isDropdownOpen ? 'open' : ''}`}
				onClick={handleToggleDropdown}
			>
				<img
					src={global}
					alt="img"
					className="language-flag"
				/>
				{t(languageOptions.find((option) => option.value === selectedLanguage)?.label)}
			</div>

			{isDropdownOpen && (
				<div className="language-options">
					{languageOptions.map((option) => (
						<div
							key={option.value}
							className="language-option"
							onClick={() => handleSelectLanguage(option.value)}
						>
							{t(option.label)}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default LanguageSelector;
