import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Layout from "./components/layout";
import PaymentPage from "./pages/PaymentPage";
import PrivacyPage from "./pages/PrivacyPage";
import RetailChains from "./pages/RetailChains";
import { useState, useEffect } from "react";
import CheckAuth from "./modal/checkAuth";
import {useDispatch} from "react-redux";
import ForResellers from "./pages/ForResselers";
import ForDistributors from "./pages/ForDistributors";
import OffertaPage from "./pages/OffertaPage";
import TexpoPage from "./pages/TexpoPage";

function App() {
	const [isOpen, setIsOpen] = useState(false);
	const [userData, setUserData] = useState('');
	const [renderComponents, setRenderComponents] = useState(true);
	const dispatch = useDispatch();

	const setIsOpenHandler = (e) => {
		setIsOpen(e);
	}
	const changeUserData = (e) => {
		setUserData(e);
	};

	useEffect(() => {
		if(userData){
			setRenderComponents(true);
		}
	}, [userData]);

	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			dispatch({type: "SWITCH_MODAL", modalType: null, modalIsOpen: false})
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<div className={`App`}>
			<CheckAuth setUserData={changeUserData}>
				{renderComponents && (
					<Routes>
						<Route
							path="/"
							element={
								<Layout
									isOpen={isOpen}
									setIsOpen={setIsOpen}
									userData={userData}
								/>
							}
						>
							<Route index element={<HomePage isOpen={isOpen} setIsOpen={setIsOpenHandler}/>} />
							<Route path="/payment" element={<PaymentPage />} />
							<Route path="/privacy" element={<PrivacyPage />} />
							<Route path="/for_distributors" element={<ForDistributors />} />
							<Route path="/for_resellers" element={<ForResellers />} />
							<Route path="/retail_chains" element={<RetailChains />} />
							<Route path="/offerta" element={<OffertaPage />} />
							<Route path="/texpo" element={<TexpoPage />} />
						</Route>
					</Routes>
				)}
			</CheckAuth>

		</div>
	);
}

export default App;
