import {useState, useEffect, useRef} from "react";
import preloaderImg from '../img/tube-spinner.svg';
import successSvg from '../img/success.svg';
import errorSvg from '../img/confirm-error.svg';
import {ReactSVG} from "react-svg";
import ReCAPTCHA from "react-google-recaptcha";
import {useTranslation} from "react-i18next";
import useLocalStorage from "../hooks/useLocalStorage";
import {useSelector, useDispatch} from "react-redux";
import {API_URL} from "../utils/config";
import GoogleAuth from "../components/GoogleAuth";


const AuthForm = () => {
	// Хуки состояний
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [message, setMessage] = useState('');
	const [currentDomain, setcurrentDomain] = useState(window.location.hostname);
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();
	const itemCaptcha = useRef(null);
	const sendBtn = useRef(null);
	const [captchaValue, setCaptchaValue] = useState(null);
	const [isDone, setIsDone] = useState(false);
	const [language, setLanguage] = useLocalStorage('language', 'ru');
	const passwordInput = useRef(null);
	const currentLogin = useRef(null);
	const [isResetShow, setIsResetShow] = useState(false);
	const passwordResetBtn = useRef(null);
	const [messageColor, setMessageColor] = useState('');

	const [disabledBtn , setDisabledBtn] = useState(false);
	const [disabledLink , setDisabledLink] = useState(false);

	const isConfirm = useSelector(state => state.multiModal.modals[0].modalData?.isConfirm) || '';
	const emailValue = useSelector(state => state.multiModal.modals[0].modalData?.emailValue) || '';
	const isAuth = useSelector(state => state.multiModal.modals[0].isAuth);

	const dispatch = useDispatch();

	// Проверка валидности email
	const setIsValidEmail = (e) => {
		const newEmail = e.target.value;
		setLogin(newEmail);
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
		const isValidEmail = emailRegex.test(newEmail);
		setError('');
		if (isValidEmail) {
			fetch(`${API_URL}/auth/check.login`, {
				method: 'POST',
				body: JSON.stringify({login: newEmail}),
			})
			.then((response) => response.json())
			.then((data) => {
				if (!isResetShow) {
					if(!isAuth){
						if (data.status === true && data.exists === true) {
							setDisabledBtn(false)
							setDisabledLink(true)
							setMessage('')
							setMessageColor('')
						} else {
							setDisabledBtn(true)
							setDisabledLink(false)
							setMessageColor('red')
							setMessage(t('I_AUTH_USER_NOT_FOUND'));
						}
					}else{
						if (data.status === true && data.exists === true) {
							setDisabledBtn(true)
							setDisabledLink(false)
							setMessageColor('red')
							setMessage(t('I_AUTH_USER_IS_FOUND'))
						} else {
							setDisabledBtn(false)
							setDisabledLink(true)
							setMessageColor('green')
							setMessage(t('I_AUTH_USER_FOUND'))
						}
					}
				}
			});
		}else{
			setDisabledBtn(false)
			setDisabledLink(false)
			setMessage('')
			setMessageColor('')
		}
	};

	// Переключение видимости кнопки восстановления пароля
	const sendResetPasswordBtn = () => {
		setError('')
		setIsResetShow(!isResetShow);
		setMessage('')
		// dispatch({type: "SWITCH_MODAL", modalType: 'resetPasswordFormModal', modalIsOpen: true})
	};


	const checkEmail = () => {
		if(login){
			fetch(`${API_URL}/auth/check.login`, {
				method: 'POST',
				body: JSON.stringify({login: login}),
			})
			.then((response) => response.json())
			.then((data) => {
				if (!isResetShow) {
					if(!isAuth){
						if (data.status === true && data.exists === true) {
							setDisabledBtn(false)
							setDisabledLink(true)
						} else {
							setDisabledBtn(false)
							setDisabledLink(false)
							setMessageColor('green')
							setMessage(t('I_AUTH_USER_FOUND'));
						}
					}else{
						if (data.status === true && data.exists === true) {
							setDisabledBtn(false)
							setDisabledLink(true)
						} else {
							setDisabledBtn(true)
							setDisabledLink(false)
							setMessageColor('red')
							setMessage(t('I_AUTH_USER_NOT_FOUND'));
						}
					}
				}
			});
		}else{
			setDisabledBtn(false)
			setMessage('')
			setMessageColor('')
		}
	}


	// Отправка запроса на восстановление пароля
	const sendResetPassword = () => {
		passwordResetBtn.current.classList.add('load');
		let body = {
			email: login || currentLogin.current.value,
			language: language,
		};

		fetch(`${API_URL}/auth/restore.send`, {
			body: JSON.stringify(body),
			method: 'POST',
		}).then(response => response.json()).then(data => {
			if (data.status === true) {
				setError('');
				setMessage(data.message);
				setIsDone(true);
			} else {
				setError(data.message);
			}
			passwordResetBtn.current.classList.remove('load');
		});
	};

	// Обработчик изменения reCAPTCHA
	const recaptchaChange = (value) => {
		setCaptchaValue(value);
	};

	// Отправка формы авторизации
	const sendAuthForm = () => {
		let body = {
			login: login || currentLogin.current.value,
			password: password || passwordInput.current.value,
			language: language,
		};

		fetch(`${API_URL}/auth/login`, {
			method: 'POST',
			body: JSON.stringify(body),
			credentials: 'include',
		}).then(response => response.json()).then(data => {
			if (data.status === true) {
				window.location.href = `${lastSegment === 'd' ? 'http' : 'https'}://cabinet.${currentDomain}/`;
			} else {
				setError(data.message);
			}
		});
	};

	// Отправка формы регистрации
	const sendRegForm = () => {
		if (captchaValue) {
			let body = {
				login: login || currentLogin.current.value,
				password: password || passwordInput.current.value,
				recaptcha: captchaValue,
				language: language,
			};

			sendBtn.current.classList.add('load');
			fetch(`${API_URL}/auth/register`, {
				method: 'POST',
				body: JSON.stringify(body),
			}).then(response => response.json()).then(data => {
				if (data.status === true) {
					setIsDone(true);
					setMessage(data.message);
				} else {
					setError(data.message);
				}
			}).catch((error) => {
				console.error('Error:', error);
			}).finally(() => {
				sendBtn.current.classList.remove('load');
			});
		} else {
			setError(t('I_FORM_RECAPTCHA_CHECK'));
		}
	};

	// Хуки эффектов
	const {t} = useTranslation();

	// Обработчик нажатия клавиши Enter
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			if (isResetShow) {
				sendResetPassword();
			} else {
				if (isAuth) {
					sendRegForm();
				} else {
					sendAuthForm();
				}
			}
		}
	};

	// Эффект для подписки и отписки от события клавиши Enter
	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAuth, captchaValue, isResetShow]);

	// Эффект для обработки подтверждения email
	useEffect(() => {
		if (isConfirm === 'done') {
			setLogin(emailValue);
			passwordInput.current.focus();
		}
	}, [isConfirm, emailValue]);

	// Обработчик ошибок
	const handleChangeError = (error) => {
		setError(error)
	}

	// Визуализация компоненты
	return (
		<div className={`i_auth_form`}>
			<div className="i_is_confirm">
				{isConfirm === 'done' ?
					<>
						<div className="i_is_confirm-done">
							<img src={successSvg} alt="done"/>
							<span>{t('I_FORM_CONFIRM_DONE')}</span>
						</div>
					</>
					: ''}
				{isConfirm === 'error' ?
					<>
						<div className="i_is_confirm-error">
							<img src={errorSvg} alt="error"/>
							<span>{t('I_FORM_CONFIRM_ERROR')}</span>
						</div>
					</> : ''}
			</div>
			<div className={`i_auth_form-title ${isDone ? 'hd' : ''}`}>
				<span>{isAuth ? t('I_FORM_REG') : t('I_FORM_AUTH')}</span>
			</div>
			<div className={`i_auth_form-items ${isDone ? 'hd' : ''}`}>
				<div className="i_auth_main">
					<div className="i_a_form-item">
						<label>Email</label>
						<input type="text" name={'USER_LOGIN'} maxLength={'50'} size={'17'}
						       value={login}
						       onChange={setIsValidEmail} ref={currentLogin}/>
					</div>
					<div className={`i_a_form-item ${isResetShow ? 'hd' : ''}`}>
						<label>{t('I_FORM_PASSWORD')}</label>
						<input type="password" name={'USER_PASSWORD'} maxLength={'225'} size={'17'}
						       autoComplete={'off'}
						       onChange={(e) => {
							       setPassword(e.target.value)
						       }} value={password} ref={passwordInput}/>
					</div>
				</div>

				{
					message && (
						<div className={`i_message ${messageColor === 'green' ? 'green': 'red'}`}>{message}</div>
					)
				}

				<div className={`i_recaptcha ${isAuth ? '' : 'hd'}`} ref={itemCaptcha}>
					<ReCAPTCHA
						sitekey="6LceO5ooAAAAAD8D-gWZKDrGMMJtWloiqZRVhYSC"
						onChange={recaptchaChange}
						hl={language}
						size={'normal'}
						isolated={true}
					/>
				</div>

				<div className={`i_a_form-item ${isResetShow ? 'hd' : ''}`} ref={sendBtn}>
					<div className="preloader"><img src={preloaderImg} alt=""/></div>
					<button className={`auth_btn ${disabledBtn ? 'disabled' : ''}`} onClick={isAuth ? sendRegForm : sendAuthForm}>
						<span>{isAuth ? t('I_FORM_REG_BTN') : t('I_FORM_AUTH_BTN')}</span></button>
				</div>

				{
					isResetShow && (
						<div className={`i_a_form-item`} ref={passwordResetBtn}>
							<div className="preloader"><img src={preloaderImg} alt=""/></div>
							<button className={'auth_btn'} onClick={sendResetPassword}><span>{t('I_FORM_RESTORE_BTN')}</span>
							</button>
						</div>
					)
				}

				{/*ЗАРЕГИСТРИРОВАТЬСЯ*/}

				{
					!isResetShow && (
						<div className={`i_a_form-item ${disabledLink ? 'disabled' : ''} forgot ${isAuth ? 'hd' : ''}`}>
							<div className="i_a_form-item-text" onClick={() => {
								checkEmail()
								dispatch({type: "CHANGE_MODAL_IS_AUTH", modalLevel:1 , isAuth: true})
								setMessage('')
								setDisabledBtn(false)
							}}>
								<span>{t('I_FORM_REG_BTN')}</span>
							</div>
						</div>
					)
				}

				{/*АВТОРИЗИВАТЬСЯ*/}
				{
					!isResetShow && (
						<div className={`i_a_form-item forgot ${isAuth ? '' : 'hd'}`}>
							<div className="i_a_form-item-text">
								<span onClick={() => {
									checkEmail()
									dispatch({type: "CHANGE_MODAL_IS_AUTH", modalLevel:1 , isAuth: false})
									setMessage('')
									setError('')
								}}>{t('I_FORM_AUTH_TITLE')}</span>
							</div>
						</div>
					)
				}

				<div className={`i_a_form-item forgot ${isAuth ? 'hd' : ''}`}>
					<div className="i_a_form-item-text">
						<span
							onClick={sendResetPasswordBtn}>{isResetShow ? t('I_FORM_RESTORE_REMEMBER') : t('I_FORM_RESTORE')}</span>
					</div>
				</div>

				<div className="i_a_form-item hd">
					<GoogleAuth setError={handleChangeError}/>
				</div>

				<div className="i_auth_form-error">
					<span dangerouslySetInnerHTML={{ __html: error }}></span>
				</div>

			</div>
			<div className={`i_form_success ${isDone ? '' : 'hd'}`}>
				<ReactSVG src={successSvg} beforeInjection={(svg) => {
					svg.classList.add('active')
				}}/>
				<span>{message}</span>
			</div>
		</div>
	);
}

export default AuthForm;
