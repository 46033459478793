import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import {API_URL} from "../utils/config";
const GoogleAuth = () => {
	const clientId = '92588799714-5s5n1086sh8dq2j9na1g9hc2h1lvl2g9.apps.googleusercontent.com';

	const handleLoginSuccess = (credentialResponse) => {
		console.log(credentialResponse)
		const token = credentialResponse.credential;
		fetch(`${API_URL}/auth/google/`, {
			method: 'POST',
			body: JSON.stringify({code: token}),
			credentials: 'include'
		}).then(response => response.text()).then(data => {
			console.log(data)
		})
	};

	const handleLoginFailure = () => {
		console.log('Login Failed');
	};

	return (
		<GoogleOAuthProvider clientId={clientId}>
			<div>
				<GoogleLogin
					onSuccess={handleLoginSuccess}
					onError={handleLoginFailure}
				/>
				{/*<button onClick={handleLogout}>Logout</button>*/}
			</div>
		</GoogleOAuthProvider>
	);
};

export default GoogleAuth;
