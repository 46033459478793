import {useTranslation} from "react-i18next";
import preloaderImg from "../img/tube-spinner.svg";
import {useState} from "react";
import {API_URL} from "../utils/config";
import Cookies from 'js-cookie';
import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";

const TexpoRegisterUser = () => {

	const {t} = useTranslation();

	const [name, setName] = useState('');
	const [company, setCompany] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [btnLoad, setBtnLoad] = useState(false);
	const dispatch = useDispatch();
	const catalogHash = useSelector(state => state.multiModal.modals[0].modalData.hash);

	const [error, setError] = useState('');

	const sendRegisterUser = async () => {
		setBtnLoad(true)

		// Делаем запрос если пользователь неавторизован , и сохраняем его данные в куки
		const response = await api.texpoApi.saveContact({	name: name, company: company, phone: phone, email: email})

		if(response.status === true){
			Cookies.set('texpo_user_id', response.id, {expires: 365 });
			const newUrl = new URL(window.location);
			newUrl.searchParams.set('catalog', catalogHash);
			window.history.pushState({}, '', newUrl);
		}else{
			setError(response.message)
		}

		setBtnLoad(false)
	}

	// Визуализация компоненты
	return (
		<div className={'i_texpo-register'}>
			<div className="i_modal-title">
				<span>Контактные данные</span>
			</div>
			<div className="i_modal-subtitle">
				<span>Для просмотра каталогов всех экспонентов заполните пожалуйста форму</span>
			</div>
			<div className="i_texpo-register-items">
				<div className="i_a_form-item">
					<label>ФИО</label>
					<input type="text" name={'USER_EMAIL'} maxLength={'50'} size={'17'} value={name} onChange={e => {
						setName(e.target.value)
					}}/>
				</div>
				<div className="i_a_form-item">
					<label>Компания</label>
					<input type="text" name={'USER_EMAIL'} maxLength={'50'} size={'17'} value={company} onChange={e => {
						setCompany(e.target.value)
					}}/>
				</div>
				<div className="i_a_form-item">
					<label>Телефон</label>
					<input
						type="text"
						name={'USER_PHONE'}
						maxLength={'50'}
						size={'17'}
						value={phone}
						onChange={e => {
							const value = e.target.value;
							// Оставляем только цифры
							const filteredValue = value.replace(/[^+\d]/g, '');
							setPhone(filteredValue);
						}}
					/>
				</div>
				<div className="i_a_form-item">
					<label>Email</label>
					<input type="text" name={'USER_EMAIL'} maxLength={'50'} size={'17'} value={email} onChange={e => {
						setEmail(e.target.value)
					}}/>
				</div>
			</div>

			<div className="i_auth_form-error">
				<span dangerouslySetInnerHTML={{__html: error}}></span>
			</div>

			<div className="i_texpo-register-button">
				<div className={`i_a_form-item ${btnLoad ? 'load' : ''}`}>
					<div className="preloader"><img src={preloaderImg} alt=""/></div>
					<button className={'auth_btn'} onClick={sendRegisterUser}>
						<span>Отправить</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default TexpoRegisterUser;
