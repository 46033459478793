import { useEffect } from "react";

function OrderWin() {
	useEffect(() => {
		// Функция, которая добавляет скрипт на страницу
		function addScript(url) {
			var script = document.createElement('script');
			script.async = true;
			script.src = url + '?' + (Date.now() / 180000 | 0);
			var firstScript = document.getElementsByTagName('script')[0];
			firstScript.parentNode.insertBefore(script, firstScript);
		}

		// Вызываем функцию для загрузки скрипта
		addScript('https://cdn-ru.bitrix24.kz/ilabr/crm/form/loader_90.js');

	}, []);


	return (
		<div className={`i_order_win j_win`} id="j_order_win">
			<div>
				<script data-b24-form="inline/90/nam522" data-skip-moving="true">
				</script>
			</div>
		</div>
	);
}

export default OrderWin;
